<template>
    <!-- 账户管理 -->

    <div class="main-pdding">
        <div class="main-box">
            <div class="title">
                <div class="t-label">
                    <label>账户管理</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加账户" : "修改账户" }}</label>
                </div>
            </div>
            <div class="setbox">
                <el-form ref="form" :model="form" label-width="150px" :rules="rules">
                    <el-form-item label="账号" prop="account">
                        <el-input v-model="form.account" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="登陆密码" prop="password">
                        <el-input v-model="form.password" placeholder="请输入登陆密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="conPws">
                        <el-input v-model="form.conPws" placeholder="请输入确认密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="actorId">
                        <el-select v-model="form.actorId" placeholder="请选择角色" @change="roleChange">
                            <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="姓名" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入本人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入联系方式"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="btn-box">
                <el-button @click="$router.push('/systemset/accountment')">取消</el-button>
                <el-button type="primary" @click="addInfo" v-if="type == 1">添加</el-button>
                <el-button type="primary" @click="editInfo" v-else-if="type == 2">修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from "@/utils/rules";
export default {
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.getRole();
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.$route.query.id) {
            this.getStaff(this.$route.query.id);
        }
    },
    data() {
        return {
            rules: rules,
            platform_id: "",
            type: "1",
            form: {
                id: "",
                userName: "",
                account: "",
                password: "",
                conPws: "",
                phone: "",
                auth: "",
                actorId: "",
            },
            roleData: [],
        };
    },
    methods: {
        roleChange(e) {
            let arr = this.roleData.filter((item) => {
                return item.id === e;
            });

            if (arr.length > 0) {
                this.form.auth = arr[0].auth;
            }
        },
        getRole() {
            const that = this;
            that.$request({
                url: that.$api.role.getRole,
                method: "post",
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                that.roleData = res.data;
            });
        },
        //获取单个账号
        getStaff(id) {
            const that = this;
            that.$request({
                url: that.$api.staff.getStaff,
                method: "post",
                data: {
                    id: id,
                },
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                let { id, account, auth, name, password, phone, actor_id } = res.data;
                that.form.id = id;
                that.form.userName = name;
                that.form.account = account;
                that.form.auth = auth;
                that.form.password = password;
                that.form.conPws = password;
                that.form.phone = phone;
                that.form.actorId = actor_id;
            });
        },
        addInfo() {
            const that = this;
            let { userName, account, password, conPws, phone, auth, actorId } = that.form;

            try {
                if (password !== conPws) {
                    throw new Error("确认密码不一致,请重新输入");
                }
            } catch (err) {
                this.$alert(err.message);
                return;
            }

            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.staff.addStaff,
                        method: "post",
                        data: {
                            account,
                            psw: password,
                            name: userName,
                            phone,
                            auth,
                            actor_id: actorId,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/systemset/accountment");
                    });
                }
            });
        },
        editInfo() {
            const that = this;
            let { id, userName, account, password, conPws, phone, auth, actorId } = that.form;

            try {
                if (password !== conPws) {
                    throw new Error("确认密码不一致,请重新输入");
                }
            } catch (err) {
                this.$alert(err.message);
                return;
            }
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.staff.editStaff,
                        method: "post",
                        data: {
                            id: id,
                            account,
                            psw: password,
                            name: userName,
                            phone,
                            auth,
                            actor_id: actorId,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/systemset/accountment");
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    padding: 33px;
    background: #fff;
    margin-top: 30px;
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}

.setbox {
    margin-top: 20px;
    color: #333333;

    .title {
        background: #edefff;
        height: 35px;
        line-height: 35px;
        padding-left: 15px;
    }
    /deep/.el-input__inner {
        width: 420px;
    }
    /deep/.input-id {
        margin-left: 20px;
        width: 100px;
    }
    /deep/.input-id .el-input__inner {
        width: 100px;
    }
    /deep/.el-form-item__content {
        // display: flex;
        // align-items: center;
    }
    .inputbox {
        //   margin-left: 15%;

        margin-top: 24px;
    }
}
</style>
